@import "@styles/variables.scss";

.logo {
  display: flex;
  align-items: center;
  @media screen and (max-width: $screen-sm) {
    display: none;
  }
}

.full_logo {
  display: flex;
  align-items: center;
}

.icon {
  display: none;
  @media screen and (max-width: $screen-sm) {
    display: flex;
    align-items: center;
  }
}

.full_icon {
  display: none;
}

.small_logo {
  display: none;
}

.small_icon {
  margin-right: 1rem;
}
