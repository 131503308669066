@import "@styles/variables.scss";

.job-offers-header {
  padding: 0 2rem 0 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $border-color-jobs;
  box-shadow: 0 1px 2px 0px rgba(0, 0, 0, 0.2);
  opacity: 1;
  transition: opacity 0.3s linear;

  @media screen and (max-width: $screen-md) {
    &.hide-header {
      height: 0;
      opacity: 0;
      transition: all 0.3s linear;
    }
  }

  .job-offers-header__content-container {
    display: flex;
    align-items: center;
    justify-content: center;

    .img-container {
      display: flex;

      a {
        height: 32px;

        .job-offers-header__header-icon {
          max-height: 32px;
          max-width: 130px;
          cursor: pointer;

          @media screen and (max-width: $screen-xs) {
            max-width: 100px;
          }
        }
      }
    }
  }
}
