@import "@styles/variables.scss";

.content {
  font-family: "Lato";
  max-width: 100%;
  border-top: solid 1px $gray-2;
  margin-top: 25px;
  padding-top: 30px;
  @media screen and (max-width: $screen-md) {
    margin-top: 0;
    padding-top: 0;
  }
}

.container {
  display: flex;
  justify-content: space-between;
  max-width: 1080px;
  margin: 0 auto;
  padding: 30px 10px 0 10px;
  margin-top: 0px;
  &:first-child {
    min-height: 20.4rem;
  }
  &:last-child {
    flex-direction: column;
    min-height: 10.8rem;
    margin-bottom: 28px;
    border-top: solid 1px $gray-3;
  }
  @media screen and (max-width: $screen-md) {
    max-width: 100vw;
    flex-direction: column;
  }
}

.social {
  width: 23vw;
  @media screen and (max-width: $screen-md) {
    width: 100%;
  }

  .appDownloadSection {
    margin-top: 20px;

    @media screen and (max-width: $screen-md) {
      margin-bottom: 30px;
    }

    .downloadAppLinks {
      display: flex;
      flex-direction: column;

      @media screen and (max-width: $screen-md) {
        flex-direction: initial;
        flex-wrap: wrap;
        justify-content: center;
      }

      .logoAppDownload {
        margin: 10px 10px 0 0;
        span {
          width: 135px !important;
          height: 40px !important;
        }
      }
    }
  }
}

.logo {
  position: relative;
  span {
    width: 110px !important;
    height: 30px !important;
    @media screen and (max-width: $screen-md) {
      width: 49% !important;
      height: 80px !important;
    }
  }
  @media screen and (max-width: $screen-md) {
    display: block;
    width: 82vw !important;
    margin-left: 1.1rem;
  }
}

.title {
  font-size: 15.5px;
  letter-spacing: -0.2px;
  color: $black;
  font-weight: bold;
  width: 160px;
  margin-bottom: 20px;
  @media screen and (max-width: $screen-md) {
    font-size: 17px;
    font-weight: 700;
    width: 195px;
    margin-bottom: 10px;
  }
}

.subtitle {
  color: $black;
  font-size: 1rem;
  font-weight: 400;
  width: 500px;
  max-width: 100%;
  margin-left: -12px;
  margin-bottom: 0;
  a {
    color: inherit;
    font-size: inherit;
    font-weight: 500;
    letter-spacing: inherit;
  }
  @media screen and (max-width: $screen-md) {
    font-size: calc(0.6rem + 0.9vw);
    letter-spacing: -0.1px;
    text-align: center;
    width: 100%;
    margin-left: unset;
    margin: 0 auto;
  }
  @media screen and (max-width: $screen-xl) {
    margin-left: unset;
  }
}

.extra {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  @media screen and (max-width: $screen-md) {
    width: 100%;
    flex-direction: column;
  }
}

.small {
  display: inline-block;
  color: $black;
  width: 40vw;
  max-width: 500px;
  padding: 2rem 0 0 0;
  margin-bottom: 3rem;
  a {
    color: $footer-link;
    text-decoration: underline;
  }
  @media screen and (max-width: $screen-md) {
    text-align: center;
    width: 100%;
    max-width: unset;
    padding-top: 1rem;
  }
}
.content-img {
  width: 23vw !important;
  max-width: 250px;
  @media screen and (max-width: $screen-md) {
    width: 36vw !important;
  }
}
