@import "@styles/variables.scss";

.collapse {
  display: unset;
  background: transparent;
  width: 100%;
  margin: 12px 0 43px 0;
  @media screen and (min-width: $screen-md) {
    display: none;
  }
  :global {
    .ant-collapse-item {
      padding: unset;
      .ant-collapse-header {
        padding: 10px 16px 3px 16px;
      }
    }
    .la-angle-up {
      font-size: 18px;
      text-shadow: 0 1px 1px $black;
      color: $black;
    }
    .la-angle-down {
      font-size: 18px;
      text-shadow: 0 0 1px #333;
      color: #7a7e8f;
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  width: 250px;
}

.title {
  font-family: "Lato";
  font-size: 17px;
  line-height: 1;
  letter-spacing: 0;
  color: $black;
  font-weight: bold;
}
.btnRegister,
.link {
  display: list-item;
  font-family: "Lato";
  font-weight: 400;
  font-size: 15.5px;
  letter-spacing: -0.2px;
  color: $footer-link;
  margin: 0.5rem 1rem 1.5rem 1rem;
  &:hover {
    color: $footer-link;
    text-decoration: underline;
  }
}

.btnRegister {
  text-align: left;
  padding: 0;
  background: transparent;
  border: none;
}
