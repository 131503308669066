@import "@styles/variables.scss";

.header {
  position: relative;
}
.image {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem 0;
  img {
    max-height: 60px;
    width: auto;
  }
}

@media (max-width: $screen-md) {
  .slogan-container {
    border-radius: 0rem !important;
  }
  .slogan {
    height: 15rem !important;
    border-radius: 0rem !important;

    &-content {
      padding: 1rem;

      h2 {
        font-size: 1.2rem !important;
      }
      p {
        font-size: 0.9rem !important;
        line-height: 1rem !important;
      }
    }
  }
}

.header-referred {
  width: 100%;
  background: white;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}

.banner {
  width: 100%;
  background: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
}

.slogan-container {
  max-width: 800px;
  margin: 2rem auto;
  border-radius: 1rem;
  overflow: hidden;
  border: 1px solid $gray-3;
}

.slogan {
  position: relative;
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  &-image {
    position: absolute !important;
    width: 100%;
    height: 100%;

    img {
      object-fit: fill !important;
      object-position: center center !important;
      width: 100% !important;
      height: 100% !important;
    }

    &.with-overlay::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.6));
    }
  }

  &-content {
    position: relative;
    z-index: 1;
    color: white;
    text-align: center;
    padding: 2rem;
    width: 100%;
    max-width: 800px;

    h2 {
      color: white;
      font-size: 2rem;
      margin-bottom: 1rem;
    }

    p {
      color: white;
      font-size: 1.1rem;
      line-height: 1.5;
    }
  }
}
