@import "@styles/variables.scss";

.job-offers-header {
  padding: 0 2rem 0 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $border-color-jobs;
  box-shadow: 0 1px 2px 0px rgba(0, 0, 0, 0.2);

  .job-offers-header__content-container {
    display: flex;
    align-items: center;

    a {
      display: flex;
      align-items: center;
      padding-right: 20px;

      .job-offers-header__header-icon {
        max-height: 32px;
        max-width: 130px;
        cursor: pointer;
        @media screen and (max-width: $screen-xs) {
          max-width: 100px;
        }
      }
    }
  }

  .job-offers-header__buttons-container {
    display: flex;
  }
}

.header {
  width: 100vw;
  height: 75px;
  border-bottom: solid 1px #e3e3e3;
  box-shadow: rgba(27, 31, 35, 0.04) 0px 1px 0px, rgba(255, 255, 255, 0.25) 0px 1px 0px inset;
  position: sticky;

  @media screen and (max-width: $screen-md) {
    height: 60px;
  }
}
