@import "@styles/variables.scss";

.profile-header__menu {
  width: 800px;
  margin-left: 3rem;
  border: none;
  height: 100%;
  color: #aeaeae;

  :global {
    .ant-menu {
      z-index: 100;
      &-item {
        border: none !important;
        :nth-child(1) {
          font-size: 15px;
          // margin-right: 0.2rem;
        }
        :nth-child(2) {
          > a {
            font-size: 13px;
            color: #aeaeae;
          }
        }

        &-selected,
        &:hover {
          :nth-child(1) {
            color: #02bc4d;
          }
          :nth-child(2) {
            > a {
              color: black;
            }
          }
        }
      }
      &-submenu {
        border: none !important;
        &-title {
          :nth-child(1) {
            font-size: 15px;
            margin-right: 0.2rem;
          }
          :nth-child(2) {
            font-size: 13px;
          }
        }
        &-active,
        &:hover &-selected {
          .ant-menu-submenu-title {
            :nth-child(1) {
              color: #02bc4d;
            }
            :nth-child(2) {
              color: black;
            }
          }
        }
      }
    }
    .ant-menu-submenu {
      &:hover {
        a {
          color: black !important;
        }
      }
    }
    .ant-menu-title-content {
      > a {
        font-size: 13px !important;
        color: #aeaeae;
      }
    }
  }

  @media screen and (max-width: $screen-md) {
    display: none;
  }
}
