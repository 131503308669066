@import "@styles/variables.scss";

.job-offer-detail {
  display: flex;
  flex-direction: row;
  justify-content: center;

  .job-offer-detail__loading {
    align-self: center;
    font-size: 80px;
    color: #737373;
  }

  .job-offer-detail__content {
    flex: 1 1 0;
    padding: 0 16px;
    max-width: 800px;
    width: 100%;

    .header-action {
      margin-bottom: 5px;

      .header-action__section {
        padding: 10px 10px 4px 10px;

        &.header-action__desktop {
          display: block;

          @media screen and (max-width: $screen-xl) {
            display: none;
          }
        }

        &.header-action__mobile {
          display: none;
          padding: 20px 10px 10px 10px;

          @media screen and (max-width: $screen-xl) {
            display: block;
          }
        }

        .header-action-btn {
          padding: 5px 10px 5px 0px;
          background-color: transparent;
          color: #000;
          border: none;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          font-size: 16px;
          line-height: 1;
          font-weight: 600;

          &.small-alt {
            font-size: 14px;
            font-weight: 400;
            color: #212121;
          }

          @media screen and (max-width: $screen-xl) {
            font-size: 13px;
          }

          :global {
            i.la {
              font-size: 22px;
              margin-right: 8px;
            }
          }
        }
      }
    }

    .header-info-section {
      display: flex;
      padding-bottom: 20px;

      &.lazy-video {
        position: relative;
        min-height: 400px;
        cursor: pointer;
      }

      &.wide-section {
        @media screen and (max-width: $screen-md) {
          margin: 0 -16px;
        }
      }

      &.large-section {
        padding-bottom: 50px;
      }

      .header-info-section__fixed {
        flex: 0 0 120px;
        position: relative;
        margin-right: 10px;

        &_link {
          color: $gray-text;
          line-height: 0;
        }

        @media screen and (max-width: $screen-md) {
          flex: 0 0 100px;
        }
      }

      .header-info-section__dynamic {
        flex: 1 1 auto;
      }
    }

    .section-mobile-separator {
      display: none;

      @media screen and (max-width: $screen-md) {
        display: block;
        margin: 0 -16px;
        height: 1px;
        background-color: #ededed;
        margin-bottom: 20px;
      }
    }

    .header-info__action-buttons {
      display: flex;

      .action-btn {
        color: #000;
        padding: 0;
        border: none;
        outline: none;
        background-color: transparent;
        font-size: 22px;
        line-height: 1;
        cursor: pointer;
        height: 34px;
        width: 34px;
        margin-right: 6px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        @media screen and (max-width: $screen-md) {
          height: 30px;
          width: 30px;
          margin-right: 3px;
        }

        &:hover {
          background-color: #d7d7d7;
        }
      }
    }

    .header-info__company-logo {
      background-color: white;
      border-radius: 6px;
      width: 100px;
      height: 100px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;

      @media screen and (max-width: $screen-md) {
        width: 84px;
        height: 84px;
      }
    }

    .header-info__light-label {
      color: #505050;
      font-size: 14px;
      line-height: 12px;
      font-weight: 300;
      margin-bottom: 2px;

      & > .header-info__light-label--separator {
        font-size: 14px;
        line-height: 1;

        @media screen and (max-width: $screen-md) {
          &::before {
            content: "\a";
            white-space: pre;
          }
        }
      }
    }

    .header-info__title-label {
      font-size: 18px;
      line-height: 20px;
      font-weight: 600;
      color: #000;
      margin-bottom: 4px;
    }

    .header-info__tags {
      display: flex;
      flex-wrap: wrap;

      @media screen and (max-width: $screen-md) {
        display: none;
      }
    }

    .header-info__tag-label {
      font-size: 14px;
      line-height: 15px;
      font-weight: 300;
      color: #757575;
      margin-right: 20px;
      margin-bottom: 3px;

      :global {
        i.la {
          margin-right: 4px;
        }
      }
    }

    .header-info__description {
      color: #505050;
      font-size: 14px;
      line-height: 16px;
      font-weight: 300;
      width: 100%;

      p {
        width: 100%;
      }

      span {
        display: block;
        width: 100%;
        white-space: inherit !important;
      }
    }

    .header-info__description--title {
      color: #505050;
      font-size: 18px;
      line-height: 18px;
      font-weight: 600;
      margin-bottom: 10px;
    }

    .header-info__requirement-label {
      font-size: 13px;
      line-height: 15px;
      font-weight: 600;
      color: #505050;
      margin-bottom: 8px;

      .header-info__requirement-label--value {
        font-weight: 300;
      }

      :global {
        i.la {
          margin-right: 4px;
        }
      }
    }

    .header-info__requirements {
      flex: 1 1 0;
      background-color: #f8f8f8;
      border-radius: 10px;
      padding: 20px;

      @media screen and (max-width: $screen-md) {
        background-color: transparent;
        border-radius: 0;
        padding: 0;
      }

      .requirements-content {
        display: flex;
        padding-top: 20px;

        .requirements-content__list {
          flex: 2 2 0;
          padding-right: 10px;
        }

        .requirements-content__actions {
          flex: 1 1 0;
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;
          padding-left: 10px;
          border-left: 1px solid #e6e6e6;

          @media screen and (max-width: $screen-md) {
            display: none;
          }
        }
      }
    }

    .header-info__share-links {
      .header-info__share-links-buttons {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;

        .share-link-btn {
          background-color: transparent;
          padding: 0;
          outline: none;
          border: none;
          cursor: pointer;
          line-height: 1;
          font-size: 30px;
          border-radius: 4px;
          height: 34px;
          width: 34px;
          margin-right: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          box-shadow: none;

          &:hover {
            box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.4);
          }

          &.share-link-btn--instagram {
            color: #d800b9;
          }

          &.share-link-btn--facebook {
            color: #4267b2;
          }

          &.share-link-btn--whatsapp {
            color: #4ac959;
          }

          &.share-link-btn--linkedin {
            color: #2867b2;
          }

          &.share-link-btn--twitter {
            color: #1da1f2;
          }

          &.share-link-btn--email {
            color: #ff6366;
            font-size: 22px;
          }

          &.share-link-btn--link {
            color: #505050;
            font-size: 24px;
          }
        }
      }
    }

    .header-info__company-info {
      flex: 1 1 0;

      .company-info-logo {
        display: flex;
        justify-content: center;

        .company-info-logo__image {
          border-radius: 6px;
          max-height: 150px;
          max-width: 80%;
        }
      }

      .company-info-divider {
        height: 1px;
        background-color: #f0f0f0;
        width: 90%;
        margin: 20px auto;

        @media screen and (max-width: $screen-md) {
          width: 100%;
        }
      }

      .company-info-links {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        width: 90%;
        margin: 0 auto;

        @media screen and (max-width: $screen-md) {
          width: 100%;
        }

        .company-info-links__link {
          color: $gray-6;
          font-size: 12px;
          line-height: 14px;
          font-weight: 400;
          text-decoration: none;
          padding: 2px;

          @media screen and (max-width: $screen-md) {
            line-height: 18px;
            margin: 8px 0;
          }
        }
      }

      .company-info-powered-by {
        text-align: center;
        padding-top: 30px;

        .company-info-powered-by__text {
          color: $gray-6;
          font-size: 12px;
          line-height: 15px;
          font-weight: 300;
        }

        .company-info-powered-by__image_container {
          position: relative;
        }

        .company-info-powered-by__image {
          height: 36px;
        }
      }
    }

    .header-info__send-answers-buttons {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: 20px;
      gap: 10px;
      margin: 0px;
      position: fixed;
      width: 100%;
      background-color: white;
      box-shadow: 8px 5px 11px 5px rgba(109, 109, 109, 0.4);
      border-radius: 10px 10px 0 0;
      bottom: 0;
      z-index: 99999;

      @media screen and (min-width: $screen-lg) {
        position: static;
        justify-content: flex-end;
        padding: 0px 20px;
        margin-bottom: 20px;
        padding-bottom: 20px;
        box-shadow: none;
      }

      .send-answer-btn {
        background-color: transparent;
        color: #aeaeae;
        min-height: 32px;
        border: none;
        border-radius: 16px;
        padding: 0 20px;
        margin-left: 10px;
        margin-bottom: 10px;
        font-size: 13px;
        line-height: 15px;
        font-weight: 500;
        cursor: pointer;

        &:disabled {
          opacity: 0.6;
          cursor: not-allowed;
        }

        &.send-btn {
          background-color: #02bc4d;
          color: #ffffff;
        }
      }
    }

    .header-info__skills {
      flex: 1 1 0;

      .header-info__skills-title {
        margin-bottom: 16px;
      }
    }

    .skill-item {
      margin-bottom: 10px;

      .skill-item__name {
        font-size: 14px;
        line-height: 1;
        font-weight: 300;
        color: #505050;
      }

      .skill-item__progress {
        display: flex;

        .skill-item__progress-level {
          padding-left: 10px;
          flex: 0 0 100px;
          font-size: 13px;
          font-weight: 300;
          color: $gray-6;
        }
      }
    }

    .header-info__application-buttons {
      display: flex;
      justify-content: flex-end;

      .applied-label {
        min-height: 36px;
        display: flex;
        align-items: center;
        font-size: 16px;
        line-height: 1;
        font-weight: 700;
        color: #505050;
      }
    }
  }
}

.magneto-ui-questionnaire {
  @media screen and (max-width: 550px) {
    height: 100%;
    overflow-y: auto;
  }

  padding-top: 15px;

  padding-bottom: 150px;

  @media screen and (min-width: $screen-lg) {
    padding-bottom: 15px;
  }

  button {
    background: transparent;
    min-height: 32px;
    border: none;
    cursor: pointer;

    i {
      margin: 0 15px 0 35px;
    }
  }

  & > div:nth-child(3) {
    & > div {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: 20px;
      gap: 10px;
      margin: 0px;
      position: fixed;
      width: 100%;
      background-color: #f6f6f6;
      box-shadow: 8px 5px 11px 5px rgba(109, 109, 109, 0.4);
      border-radius: 10px 10px 0 0;
      bottom: 0;
      z-index: 99999;
      left: 0;

      @media screen and (min-width: $screen-lg) {
        position: static;
        justify-content: flex-end;
        padding: 0px 20px;
        margin-bottom: 20px;
        padding-bottom: 20px;
        background-color: white;
        box-shadow: none;
      }

      button {
        padding: 12px 0;
        font-weight: 500;
        font-size: 15px;
        line-height: 15px;
        width: 145px;
        color: $black;
      }

      button:nth-child(2) {
        color: $blue-dark;
        background-color: $green-light;
        padding: 12px 17px;
        border-radius: 20px;
        width: auto;
        min-width: 145px;
      }
    }
  }
}

.disabled-btn {
  background-color: #f5f5f5 !important;
  border: 1px solid #d9d9d9 !important;
  color: rgba(0, 0, 0, 0.25) !important;
  opacity: 0.8 !important;
  cursor: not-allowed !important;
}
