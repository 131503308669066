@import "@styles/variables.scss";

.content {
  display: flex;
  flex-direction: column;
  width: 23vw;
  display: none;
  @media screen and (min-width: $screen-md) {
    display: flex;
  }
}

.subMenus {
  display: flex;
  flex-direction: column;
}

.title {
  font-family: "Lato";
  font-size: 16px;
  letter-spacing: 0;
  color: $black;
  font-weight: bold;
  margin-bottom: 20px;
}

.btnRegister,
.link {
  font-family: "Lato";
  font-weight: 400;
  font-size: 15.5px;
  letter-spacing: -0.2px;
  color: $footer-link;
  margin-bottom: 14px;
  &:hover {
    color: $footer-link;
    text-decoration: underline;
  }
}

.btnRegister {
  text-align: left;
  padding: 0;
  background: transparent;
  border: none;
}
