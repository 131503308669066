@import "@styles/variables.scss";

.item {
  font-size: 25px;
  color: $black;
  margin-right: 1rem;
  &:hover,
  &:active {
    color: $black;
  }
}
