@import "@styles/variables.scss";

.application-button {
  height: 36px;
  border-radius: 18px;
  border: none;
  outline: none;
  padding: 0 30px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  line-height: 1;
  font-weight: 600;
  color: #212121;

  &.apply-btn {
    background-color: #c7ffe9;

    :global {
      i.la {
        color: #00ad6b;
        font-size: 20px;
        line-height: 1;
        margin-right: 6px;
      }
    }

    @media screen and (max-width: $screen-md) {
      color: #212121;
      background-color: #c7ffe9;

      :global {
        i.la {
          color: #fff;
        }
      }
    }

    &:hover {
      color: #212121;
      background-color: #c7ffe9;

      :global {
        i.la {
          color: #fff;
        }
      }
    }
  }

  &.requirement-btn {
    height: 40px;
    border-radius: 20px;
    padding: 0 18px;
  }
}

.internal-msg {
  background-color: #fffaef;
  padding: 10px;
  border-radius: 12px;
  color: #14141c;
  display: flex;
  align-items: center;
  @media screen and (max-width: $screen-md) {
    .internal-msg-img {
      display: none !important;
    }
  }
  .internal-container-info {
    margin-left: 1em;
    display: flex;
    flex-direction: column;
    .internal-container-info-title {
      font-weight: 700;
    }
  }
}
