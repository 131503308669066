@import "@styles/variables.scss";

.internal-linking {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 32px 0;
  padding: 0 20px;

  @media screen and (max-width: $screen-md) {
    padding: 0 10px;
  }

  .internal-linking__content {
    max-width: 1366px;

    @media screen and (max-width: $screen-md) {
      width: 100%;
    }

    .generated-links__content {
      display: flex;
      flex-wrap: wrap;
      margin-top: 32px;

      .links {
        background-color: $background-btn;
        border-radius: 20px;
        display: block;
        font-family: "Lato";
        font-weight: 400;
        font-size: 14px;
        padding: 10px;
        // letter-spacing: -0.2px;
        color: #333333;
        margin: 0 12px 20px 0;
      }

      @media screen and (max-width: $screen-md) {
        height: 300px;
        overflow: hidden;

        &.loadFullList {
          height: inherit;
          overflow: visible;
        }
      }
    }
  }

  .loadMoreLinksBtn {
    display: none;
    visibility: visible;
    text-decoration: underline;
    color: $landing-btn-apply;
    font-size: 15px;

    @media screen and (max-width: $screen-md) {
      display: block;
      visibility: visible;
    }
  }
}
