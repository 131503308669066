@import "@styles/variables.scss";

.sign-up-button,
.btnIframe {
  margin-left: 10px;
  background: $btn-sign-up;
  border-radius: 20px;
  color: $black;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 0.6rem;
  height: 38px;
  align-items: center;
  border: unset;
  padding: 0 1rem;
  cursor: pointer;
  @media screen and (max-width: $screen-lg) {
    padding: unset;
    width: 38px;
    justify-content: center;
  }
  &:hover,
  &:active {
    background-color: $btn-sign-up !important;
  }
}

.btnIframe {
  @media screen and (max-width: $screen-lg) {
    padding: 0 1rem;
    width: auto;
    justify-content: space-between;
  }
}

.spinner {
  font-size: 24px;
  color: $gray-title;
}

.text-button {
  display: block;
  @media screen and (max-width: $screen-lg) {
    display: none;
  }
}

.textIframe {
  display: block;
}
